<template>
	<div id="australia-day-promotion">
		<hr class="my-16" />
		<h4 class="font-body text-xl font-medium">Australia day promotion</h4>
		<div class="my-2.5 space-y-8 font-body text-lg">
			<p>
				This offer is available to new subscribers in NSW, QLD, and VIC
				aged 21 years or over. The promotion begins on 20/1/25 at 12:01
				AM AEDT and ends on 27/1/25 at 11:59 PM AEDT ("Promotional
				Period").
			</p>
			<ul class="li-padding-left space-y-5 pl-5">
				<li>
					The offer is limited to the first 100 new subscribers who
					place an order during the Promotional Period.
				</li>
				<li>
					Each individual is allowed only one subscription under this
					promotion.
				</li>
				<li>
					Eligible subscribers will receive one (1) $100 Coles Gift
					Card and a picnic set each. Gift cards and picnic sets are
					non-transferable and cannot be redeemed for cash.
				</li>
				<li>Swaps are not included in this promotion.</li>
				<li>
					Carbar reserves the right to verify subscriptions and
					disqualify fraudulent entries.
				</li>
				<li>
					In the event of unforeseen circumstances, carbar may
					substitute the gift card or the picnic set with an
					alternative reward of equal or greater value.
				</li>
				<li>
					This offer excludes fleet vehicles and tools-of-trade
					subscriptions.
				</li>
				<li>
					The promotion cannot be combined with any other discounts or
					sales offers, except for the refer-a-friend program.
				</li>
			</ul>
			<p>
				Upfront and initial fees, as well as standard carbar
				subscription terms and conditions, apply.
			</p>
		</div>
	</div>
</template>
