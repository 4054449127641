import { defineStore } from 'pinia'

const expiryDate = new Date()
expiryDate.setHours(23, 59, 59, 999)

export const useCampaignsStore = defineStore('campaigns', {
	namespaced: true,
	state: () => ({
		// Campaigns
		campaigns: {},
		campaignsStatus: {},
		primaryCampaignKey: '',

		// Novated Lease Promo
		novatedLeasePromo: {
			isPreselected: false,
		},
	}),
	getters: {
		showCampaignStatus: (state) => (campaignName) => {
			const campaignStatus = state.campaignsStatus[campaignName]
			return campaignStatus
		},

		getPrimaryCampaignKey: (state) => {
			return state.primaryCampaignKey
		},

		isNovatedLeasePreselected: (state) =>
			state.novatedLeasePromo.isPreselected,
	},
	actions: {
		checkCampaignStatus() {
			this.campaignsStatus = {}

			Object.keys(this.campaigns).forEach((campaignName) => {
				const campaign = this.campaigns[campaignName]

				this.campaignsStatus[campaignName] = isCampaignActive(
					campaign.start_date,
					campaign.end_date
				)
			})

			setPrimaryCampaignKey()

			if (
				(this.campaignsStatus[this.primaryCampaignKey] =
					isCampaignActive(
						this.campaignsStatus[this.primaryCampaignKey]
							.start_date,
						this.campaignsStatus[this.primaryCampaignKey].end_date
					))
			) {
				useCookie('campaigns')
				refreshCookie('campaigns')
			}
		},

		completePreselection() {
			this.novatedLeasePromo.isPreselected = false
		},
	},
	persist: {
		pick: ['campaignsStatus', 'novatedLeasePromo', 'primaryCampaignKey'],

		storage: piniaPluginPersistedstate.cookies({
			sameSite: 'strict',
			expires: expiryDate,
		}),
	},
})
