<template>
	<div class="w-full">
		<div
			class="relative flex flex-col items-center overflow-hidden rounded from-[#005A45] to-[#024C3A] px-4 py-8 bg-gradient-[137deg] md:max-h-[250px] md:px-6 lg:flex-row lg:p-6"
		>
			<div
				class="relative z-10 m-auto flex flex-col items-center justify-center gap-2 md:flex-row"
			>
				<div class="w-fit">
					<div class="flex flex-col gap-1 text-center md:text-left">
						<p class="text-xl text-white">26-28 Dec 2024</p>
						<p
							class="bg-gradient-to-r from-[#D7A65A] to-[#A37C3E] bg-clip-text pb-1 font-heading text-[2rem] leading-none text-transparent"
						>
							Boxing day giveaway
						</p>
						<p class="text-xl text-white">
							<span class="font-bold">$200 Coles Gift Card</span>
							for the first 100 new subscribers!
						</p>
					</div>
					<TermsAndConditions
						:is-full-width="false"
						custom-class="mt-4 text-center md:text-left"
						font-color="text-gray-500"
					>
						*Limited time offer,
						<CarbarLink
							text="terms & conditions"
							text-color="text-gray-500 hover:text-white"
							underline-color="underline-gray-500 hover:decoration-white"
							font-size="text-xsm"
							class="cursor-pointer"
							@click="
								goToPageAndScroll(
									'/terms-and-conditions',
									'#boxing-day-tnc',
									true
								)
							"
						/>
						apply
					</TermsAndConditions>
				</div>

				<div
					class="order-first md:order-none md:h-[180px] lg:h-[200px]"
				>
					<CarbarImage
						:image="keyvisual"
						alt-text="Boxing day giveaway"
						class="relative md:h-[180px] lg:h-[200px]"
						densities="x1 x2"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import TermsAndConditions from '@/components/fineprint/TermsAndConditions.vue'
import CarbarLink from '@/components/links/CarbarLink.vue'

const keyvisual = 'img/campaigns/boxing-day-sale.png'
</script>
