<template>
	<div id="march-giveaway-promotion">
		<hr class="my-16" />
		<h4 class="font-body text-xl font-medium">March Giveaway promotion</h4>

		<div class="mt-8 space-y-5 font-body text-lg">
			<ul class="li-padding-left space-y-5 pl-5">
				<li>
					This promotion is open to new subscribers in NSW, QLD, and
					VIC aged 21+.
				</li>
				<li>
					Three (3) winners will each receive first two (2) weeks free
					on their carbar subscription when they subscribe for at
					least four (4) weeks (non-transferable, no cash
					alternative), available on select vehicles only.
				</li>
				<li>
					Eligible vehicles for the March giveaway are limited to
					those with $0 upfront fee and weekly payments up to $250.
					Availability may vary, and certain models may be excluded.
				</li>
				<li>
					Only one (1) subscription per person is eligible for this
					offer.
				</li>
				<li>Swaps are not included in the promotion.</li>
				<li>
					Carbar reserves the right to verify entries and disqualify
					fraudulent submissions.
				</li>
				<li>
					In the event of unforeseen circumstances, carbar may
					substitute the prize with one of equal or greater value.
				</li>
				<li>
					This offer excludes fleet vehicles and tools-of-trade
					subscriptions and cannot be combined with other discounts,
					except for the refer-a-friend program.
				</li>
				<li>Standard carbar subscription fees and terms apply.</li>
			</ul>
		</div>
	</div>
</template>
