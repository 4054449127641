<template>
	<div class="w-full">
		<div
			class="relative flex flex-col items-center overflow-hidden rounded bg-gray-200 sm:h-[350px] md:h-[250px] md:max-h-[250px] lg:flex-row"
		>
			<div class="absolute bottom-0 right-0 md:-right-20">
				<CarbarImage
					:image="keyvisual"
					alt-text="Boxing day giveaway"
					class="relative h-[180px] md:h-[200px] lg:h-[250px]"
					densities="x1 x2"
				/>
			</div>

			<div
				class="relative z-10 mb-24 w-full px-6 py-8 md:mb-0 md:w-full lg:left-[100px] xl:left-[200px]"
			>
				<div class="w-fit md:w-[300px] xl:w-[400px]">
					<div class="flex flex-col gap-1 text-center md:text-left">
						<p
							class="pb-1 font-heading text-[2rem] leading-none text-blue-400 md:text-[2rem] xl:pr-[100px]"
						>
							Your adventure starts here!
						</p>
						<p class="text-xl text-gray-700">
							Celebrate Australia Day and stand a chance to win
							<span class="font-medium text-gray-700">
								$100 Coles gift card + a picnic set*
							</span>
						</p>
					</div>
					<TermsAndConditions
						:is-full-width="false"
						custom-class="mt-4 text-center md:text-left"
						font-color="text-gray-600"
					>
						*Limited time offer,
						<CarbarLink
							text="terms & conditions"
							text-color="text-gray-600 hover:text-gray-700"
							underline-color="underline-gray-600 hover:decoration-gray-700"
							font-size="text-xsm"
							class="cursor-pointer"
							@click="
								goToPageAndScroll(
									'/terms-and-conditions',
									'#australia-day-promotion',
									true
								)
							"
						/>
						apply
					</TermsAndConditions>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import TermsAndConditions from '@/components/fineprint/TermsAndConditions.vue'
import CarbarLink from '@/components/links/CarbarLink.vue'

const keyvisual = 'img/campaigns/australia-day-sale.webp'
</script>
