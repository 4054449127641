<template>
	<div id="valentines-day-promotion">
		<hr class="my-16" />
		<h4 class="font-body text-xl font-medium">Valentine's day promotion</h4>

		<div class="mt-8 space-y-5 font-body text-lg">
			<p>
				This offer is available to new and current subscribers in NSW,
				QLD, and VIC aged 21 years or over. The promotion begins on
				1/2/25 at 12:01 AM AEDT and ends on 14/2/25 at 11:59 PM AEDT
				("Promotional Period").
			</p>
			<ul class="li-padding-left space-y-5 pl-5">
				<li>
					Only three (3) winners will receive two (2) weeks off their
					car subscription and a sponsored dinner for two valued up to
					$200. Winners will be selected based on the most engaging
					and creative love stories submitted.
				</li>
				<li>
					Each individual is allowed only one subscription under this
					promotion.
				</li>
				<li>
					Eligible subscribers will receive one (1) two-weeks off
					their subscription and a sponsored dinner valued up to $200
					each. Prizes are non-transferable and cannot be redeemed for
					cash.
				</li>
				<li>Swaps are not included in this promotion.</li>
				<li>
					Carbar reserves the right to verify subscriptions and
					disqualify fraudulent entries.
				</li>
				<li>
					In the event of unforeseen circumstances, carbar may
					substitute the prize with an alternative reward of equal or
					greater value.
				</li>
				<li>
					This offer excludes fleet vehicles and tools-of-trade
					subscriptions.
				</li>
				<li>
					The promotion cannot be combined with any other discounts or
					sales offers, except for the refer-a-friend program.
				</li>
				<li>
					Upfront and initial fees, as well as standard carbar
					subscription terms and conditions, apply.
				</li>
			</ul>
		</div>
	</div>
</template>
