import { fromZonedTime } from 'date-fns-tz'
import { isAfter, isBefore } from 'date-fns'
import { useCampaignsStore } from '@/stores/campaigns'
import HalloweenSaleBanner from '@/components/campaigns/banners/HalloweenSaleBanner.vue'
import BlackFridayPreSaleBanner from '@/components/campaigns/banners/BlackFridayPreSaleBanner.vue'
import BoxingDaySaleBanner from '@/components/campaigns/banners/BoxingDaySaleBanner.vue'
import YearEndSaleBanner from '@/components/campaigns/banners/YearEndSaleBanner.vue'
import ZeroUpfrontFeeBanner from '@/components/campaigns/banners/ZeroUpfrontFeeBanner.vue'
import SpringSaleBannerRefresh from '@/components/campaigns/banners/SpringSaleBannerRefresh.vue'
import WorldEvDayBanner from '@/components/campaigns/banners/WorldEvDayBanner.vue'
import AustraliaDaySaleBanner from '@/components/campaigns/banners/AustraliaDaySaleBanner.vue'
import AustraliaDayTerms from '@/components/campaigns/terms-and-conditions/AustraliaDayTerms.vue'
import ValentinesDayTerms from '@/components/campaigns/terms-and-conditions/ValentinesDayTerms.vue'
import ValentinesDaySaleBanner from '@/components/campaigns/banners/ValentinesDaySaleBanner.vue'
import MarchGiveawayTerms from '@/components/campaigns/terms-and-conditions/MarchGiveawayTerms.vue'
import MarchGiveawayBanner from '@/components/campaigns/banners/MarchGiveawayBanner.vue'
import AprilEasterTerms from '@/components/campaigns/terms-and-conditions/AprilEasterTerms.vue'
import AprilEasterBanner from '@/components/campaigns/banners/AprilEasterBanner.vue'

export const isCampaignActive = (startDate, endDate) => {
	// Define the AEDT time zone
	const timeZone = 'Australia/Sydney'

	// If startDate or endDate is not provided or is invalid, return false
	if (!startDate && !endDate) {
		return false
	}

	// Create the campaign date in AEDT UTC
	const campaignStartDateStr = startDate
	const campaignEndDateStr = endDate
	const campaignStartDateUTC = fromZonedTime(campaignStartDateStr, timeZone)
	const campaignEndDateUTC = fromZonedTime(campaignEndDateStr, timeZone)

	// Get the current date in UTC
	const nowUTC = new Date()

	// Check if the current date is between campaign start and end date
	return (
		isBefore(campaignStartDateUTC, nowUTC) &&
		isAfter(campaignEndDateUTC, nowUTC)
	)
}

export const getCampaignBadgeInfo = (discountSet, customBadge) => {
	// Set default values in case tag doesn't match any case
	let badgeText = discountSet.badge
	let badgeColor = 'bg-blue-400'

	// If a custom badge is provided, use that as the text
	if (customBadge) {
		badgeText = customBadge
	} else {
		// Determine text and badgeColor based on tag
		switch (discountSet.tag) {
			case 'spring_sales':
				badgeText = 'Spring Sale'
				badgeColor = 'bg-orange-400'
				break
			case 'halloween_sales':
				badgeText = '🎃 Halloween Treats!'
				badgeColor = 'bg-orange-400'
				break
			case 'blackfriday_sales':
				badgeText = discountSet.badge
				badgeColor = 'bg-black'
				break
			case 'yearend_sales':
				badgeText = discountSet.badge
				badgeColor =
					'from-[#00A8BB] via-[#0382cc] to-[#0382cc] bg-gradient-[137deg]'
				break
			case 'april_easter':
				badgeText = discountSet.badge
				badgeColor = 'from-[#2E7661] to-[#719144] bg-gradient-[137deg]'
				break
			default:
				// Use the default values set above
				break
		}
	}

	return { badgeText, badgeColor }
}

// To show secondary campaign promotion
// ie. novated lease Tesla promo
export const showNovatedLeaseCampaign = (
	campaignKey = 'novated_lease_tesla_incentive'
) => {
	const campaigns = useCampaignsStore()
	const showCampaign = campaigns.showCampaignStatus(campaignKey)
	const isNovatedLeaseStickyActive = computed(() => showCampaign)

	return { isNovatedLeaseStickyActive }
}

export const showYearEndSticky = (campaignKey = 'boxing_day_sale') => {
	const campaigns = useCampaignsStore()
	const showCampaign = campaigns.showCampaignStatus(campaignKey)
	const isYearEndStickyActive = computed(() => showCampaign)

	return { isYearEndStickyActive }
}

export const setPrimaryCampaignKey = () => {
	const campaignKeys = [
		'zero_upfront_sale',
		'world_ev_day_sale',
		'spring_sale',
		'halloween_sale',
		'blackfriday_sale',
		'end_of_year_sale',
		'boxing_day_sale',
		'australia_day_sale',
		'valentine_day_sale',
		'march_giveaway',
		'april_easter',
	]

	const campaigns = useCampaignsStore()
	campaignKeys.forEach((key) => {
		if (key in campaigns.campaigns) {
			campaigns.primaryCampaignKey = key
		}
	})
}

export const getBannerComponent = (campaignKey) => {
	switch (campaignKey) {
		case 'zero_upfront_sale':
			return ZeroUpfrontFeeBanner
		case 'world_ev_day_sale':
			return WorldEvDayBanner
		case 'spring_sale':
			return SpringSaleBannerRefresh
		case 'halloween_sale':
			return HalloweenSaleBanner
		case 'blackfriday_sale':
			return BlackFridayPreSaleBanner
		case 'end_of_year_sale':
			return YearEndSaleBanner
		case 'boxing_day_sale':
			return BoxingDaySaleBanner
		case 'australia_day_sale':
			return AustraliaDaySaleBanner
		case 'valentine_day_sale':
			return ValentinesDaySaleBanner
		case 'march_giveaway':
			return MarchGiveawayBanner
		case 'april_easter':
			return AprilEasterBanner
		default:
			return
	}
}

export const getTermsComponent = (campaignKey) => {
	switch (campaignKey) {
		case 'australia_day_sale':
			return AustraliaDayTerms
		case 'valentine_day_sale':
			return ValentinesDayTerms
		case 'march_giveaway':
			return MarchGiveawayTerms
		case 'april_easter':
			return AprilEasterTerms
		default:
			return
	}
}
