<template>
	<div class="w-full">
		<div
			class="relative flex min-h-[230px] flex-col overflow-hidden rounded bg-[#DAF1FF] md:flex-row lg:flex-row"
		>
			<div
				class="md:absolute md:left-[200px] lg:relative lg:-left-[100px] lg:order-last xl:left-[0px]"
			>
				<CarbarImage
					:image="keyvisual"
					alt-text="Boxing day giveaway"
					class="relative h-[180px] object-contain sm:h-[220px] xl:h-[250px]"
					densities="x1 x2"
				/>
			</div>

			<div
				class="relative z-10 flex w-full items-center p-6 md:left-[30px] md:mb-0 md:w-[430px] md:px-8 lg:left-[140px] lg:pl-8 xl:left-[190px] xl:w-[460px]"
			>
				<div class="w-fit md:w-[350px] xl:w-[460px]">
					<div
						class="flex flex-col items-center gap-1 text-center md:items-start md:text-left"
					>
						<p
							class="pb-2 font-heading text-[2rem] leading-none text-gray-700 md:text-[2rem]"
						>
							Want to drive
							<br
								class="hidden 2xs:block sm:hidden md:block xl:hidden"
							/>
							for
							<span class="text-blue-400">Free*</span>
							?
						</p>
						<p class="text-xl leading-tight text-gray-700">
							Join our
							<span class="font-medium text-blue-400">
								March Giveaway
							</span>
							and stand a chance to win
							<span class="font-medium text-blue-400">
								2 weeks free subscription
							</span>
						</p>
						<NuxtLink
							class="w-fit"
							href="https://www.instagram.com/p/DGng31XBQQr"
							target="_blank"
						>
							<div
								class="my-2 flex w-fit flex-row items-center justify-between gap-2 rounded-md bg-blue-400 px-3 py-1.5 text-white transition-all duration-300 ease-in-out hover:bg-sky-600"
							>
								<IconChevronRight />
								Learn how to enter
							</div>
						</NuxtLink>
					</div>
					<TermsAndConditions
						:is-full-width="false"
						custom-class="mt-4 text-center md:text-left"
						font-color="text-gray-600"
					>
						*
						<CarbarLink
							text="Terms & conditions"
							text-color="text-gray-600 hover:text-gray-700"
							underline-color="underline-gray-600 hover:decoration-gray-700"
							font-size="text-xsm"
							class="cursor-pointer"
							@click="
								goToPageAndScroll(
									'/terms-and-conditions',
									'#march-giveaway-promotion',
									true
								)
							"
						/>
						apply, refer to the caption for giveaway details
					</TermsAndConditions>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import TermsAndConditions from '@/components/fineprint/TermsAndConditions.vue'
import CarbarLink from '@/components/links/CarbarLink.vue'
import IconChevronRight from '@/assets/svg/common/ico-chevron-right.svg'

const keyvisual = 'img/campaigns/march-giveaway.webp'
</script>
